import { useState } from 'react';
import styled from 'styled-components';
import phoneIcon from './images/phone-icon.png'
import logo from './images/logo.png'
import back1 from './images/back1.png'
import slide1 from './images/slide1.jpg'
import icon1 from './images/icon1.png'
import icon2 from './images/icon2.png'
import icon3 from './images/icon3.png'
import image1 from './images/image1.png'
import menu1 from './images/menu1.png'
import menu2 from './images/menu2.png'
import combo1 from './images/combo1.png'
import combo2 from './images/combo2.png'
import combo3 from './images/combo3.png'


function App() {
  const [showMenu, setShowMenu] = useState()
  return (
    <Container>

      <Header >
        <Contact>
          <PhoneIcon src={phoneIcon} alt='phone-icon' />
          <div>
            <Phone href='tel:+14502340800'>450-234-0-800</Phone>
            <Address>1351, chemin d'Oka, J0N 1E0</Address>
          </div>
        </Contact>
        <Logo src={logo} alt='logo' />
        <Nav>
          <NavItem selected={!showMenu} onClick={() => setShowMenu(false)}>HOME</NavItem>
          <NavItem selected={showMenu} onClick={() => setShowMenu(true)} >MENU</NavItem>
        </Nav>
      </Header>

      {
        showMenu ?
          (
            <MenuContainer>
              <MenuImage src={menu1} alt='manu1' />
              <MenuImage src={menu2} alt='manu2' />
            </MenuContainer>
          ) : (
            <>
              <Main>
                <PizzaImage src={image1} alt="image1" />
              </Main>

              <HotSales>COMBOS</HotSales>

              <ComboWrapper>
                <ComboImage src={combo1} alt='combo1' />
                <ComboImage src={combo2} alt='combo2' />
                <ComboImage src={combo3} alt='combo3' />
              </ComboWrapper>

              <Features>
                <Feature>
                  <img src={icon1} alt="icon1" />
                  <p>QUALITY FOODS</p>
                </Feature>
                <Feature>
                  <img src={icon3} alt="icon3" />
                  <p>FASTEST DELIVERY</p>
                </Feature>
                <Feature>
                  <img src={icon2} alt="icon2" />
                  <p>ORIGINAL RECIPES</p>
                </Feature>
              </Features>
            </>
          )
      }
      <Footer>
        Copyright © 2023 Restaurant Mozza Pizza. All Rights Reserved.
      </Footer>
    </Container>
  );
}

export default App;

const HotSales = styled.h2`
  letter-spacing: 6px;
  font-size: 32px;
  color: #dce4e8;
  font-family: "PT Sans Narrow";
  text-align: center;
  font-weight: 400;
`;

const ComboWrapper = styled.div`
  display: flex;
  padding-top: 54px;
  padding-bottom: 63px;

  @media (max-width: 768px){
     flex-direction: column;
  }
`;

const ComboImage = styled.img`
  width: 33.3%;

  @media (max-width: 768px){
     width: 100%;
  }
`;

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const MenuImage = styled.img`
  width: 100%;
  cursor: pointer;
  display: block;
`;

const PizzaImage = styled.img`
  height: 40vw;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #121518;
  min-height: 100vh;
`;

const Footer = styled.footer`
  text-align: center; 
  color: #fff;
  padding: 20px 0;
  margin-top: auto;
`;

const Main = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vw;
  background-image: url(${slide1});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const Feature = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    margin-bottom: 40px;
  }

  p {
    color: rgb(18, 22, 24);
    letter-spacing: 5px;
    font-size: 24px;
    line-height: 24px;
    font-weight: 400;
    text-transform: uppercase;
    word-wrap: break-word;
    margin-bottom: 20px;
  }
`;

const Features = styled.div`
  display: flex;
  padding-top: 54px;
  padding-bottom: 63px;
  background-image: url(${back1});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 768px){
    flex-direction: column;
  }
`;

const Header = styled.div`
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  background-color: #121518;
`;

const Contact = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 40px;
`;

const PhoneIcon = styled.img`
  width: 28px;
  height: 40px;
  margin-right: 10px;
`;

const Phone = styled.a`
  color: white;
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 2px;
  text-decoration: none;
`;

const Address = styled.p`
  color: white;
  font-size: 14px;
  opacity: 0.7;
  margin-top: 2px;
  letter-spacing: 3px;
`;

const Logo = styled.img`
  display: block;
  width: 300px;
  height: 150px;
  margin: 20px auto;
`;

const Nav = styled.ul`
  display: flex;
  justify-content: center;
  gap: 35px;

`;

const NavItem = styled.li`
  list-style: none;
  color: ${({ selected }) => selected ? '#ffc851' : '#fff'};
  border-bottom: ${({ selected }) => !selected ? '1px solid transparent' : '1px solid #ffc851'};
  text-transform: uppercase;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 4px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 20px;
  cursor: pointer;

  &:hover {
    color:#ffc851;
    transition: 0.3s;
    border-bottom: 1px solid #ffc851;
  }
`;